<template>
  <ul>
    <div v-if="UserData.role !== 'zean'" class="row mt-1 mb-1">
      <div class="col bg-noti">
        <router-link
          :to="{ name: 'withdraw' }"
          style="color: #3d195b; font-size: 12px"
        >
          <div
            class="d-flex justify-content-center align-items-center"
            style="margin-top: 2px"
          >
            <b-badge
              rounded
              :variant="alertwit_check ? 'danger' : 'secondary'"
              class="mr-25"
              :class="alertwit_check ? 'blob red' : ''"
            >
              {{ alertwit ? (alertwit.length ? alertwit.length : 0) : 0 }}
            </b-badge>
            <span class="text-white">แจ้งถอนไทย</span>
          </div>
        </router-link>
      </div>
      <div class="col bg-noti">
        <router-link
          :to="{ name: 'withdraw-kr' }"
          style="color: #3d195b; font-size: 12px"
        >
          <div
            class="d-flex justify-content-center align-items-center"
            style="margin-top: 2px"
          >
            <b-badge
              rounded
              :variant="alertwit_check_kr ? 'danger' : 'secondary'"
              class="mr-25"
              :class="alertwit_check_kr ? 'blob red' : ''"
            >
              {{
                alertwit_kr ? (alertwit_kr.length ? alertwit_kr.length : 0) : 0
              }}
            </b-badge>
            <span class="text-white">แจ้งถอนเกาหลี</span>
          </div>
        </router-link>
      </div>
    </div>
    <!-- แบบเก่า -->
    <!-- <div
      v-if="UserData.role !== 'theme' && UserData.role !== 'marketing'"
      class="pl-1 pr-1 border-primary rounded mr-1 ml-1 mb-1 bg-balance"
    >
      <div class="d-flex justify-content-between">
        <p>บัญชีฝาก SCB 1</p>
        <p>{{ deposit_balance ? Commas(deposit_balance) : 0 }} บาท</p>
      </div>
      <div
        class="d-flex justify-content-between"
      >
        <p>บัญชีฝาก SCB 2</p>
        <p>{{ deposit2_balance ? Commas(deposit2_balance ? deposit2_balance : 0) : 0 }} บาท</p>
      </div>
      <div
        class="d-flex justify-content-between"
      >
        <p>บัญชีฝาก SCB 3</p>
        <p>{{ deposit3_balance ? Commas(deposit3_balance ? deposit3_balance : 0) : 0 }} บาท</p>
      </div>
      <div class="d-flex justify-content-between">
        <p>บัญชีฝาก KBANK</p>
        <p>{{ deposit_kbank_balance ? Commas(deposit_kbank_balance) : 0 }} บาท</p>
      </div>
      <div class="d-flex justify-content-between">
        <p>บัญชีถอน KBANK</p>
        <p>{{ withdraw_kbank_balance ? Commas(withdraw_kbank_balance) : 0 }} บาท</p>
      </div>
      <div class="d-flex justify-content-between">
        <p>บัญชีถอน SCB</p>
        <p>{{ withdraw_scb_balance ? parseFloat(withdraw_scb_balance).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0 }} บาท</p>
      </div>
      <div
        v-if="crypto_address"
        class="d-flex justify-content-between"
      >
        <p>Crypto USDT:</p>
        <p>{{ crypto_usdt ? Commas(crypto_usdt) : 0 }} usdt</p>
      </div>
      <div
        v-if="crypto_address"
        class="d-flex justify-content-between"
      >
        <p>Crypto BNB:</p>
        <p>{{ crypto_bnb ? Commas(crypto_bnb) : 0 }} bnb</p>
      </div>
    </div> -->
    <!-- แบบใหม่ pg ใช้หัวเดียวอยู่ -->
    <div
      v-if="UserData.role !== 'zean'"
      class="pl-1 pr-1 border-primary rounded mr-1 ml-1 mb-1 bg-balance"
    >
      <div
        v-for="item in BankData.filter((ele) => ele.bankcode == '014')"
        :key="item.name"
      >
        <div v-if="item.no" class="d-flex justify-content-between">
          <p>
            บัญชีฝาก
            {{
              item.bankcode == "014"
                ? "SCB"
                : item.bankcode == "004"
                ? "KBANK"
                : item.bankcode == "903"
                ? "HANA"
                : "-"
            }}
            {{ item.no }}
          </p>
          <p>
            {{ item.balance ? Commas(item.balance) : 0 }}
            {{ item.bankcode == "903" ? "วอน" : "บาท" }}
          </p>
        </div>

      
      </div>
      <div class="d-flex justify-content-between">
          <p>
            บัญชีฝาก
           SCB 2
          </p>
          <p>
            {{ BankData[1].balance ? Commas(BankData[1].balance) : 0 }}
            {{ BankData[1].bankcode == "903" ? "วอน" : "บาท" }}
          </p>
        </div>
      <div
        v-for="item in BankData.filter((ele) => ele.bankcode != '014')"
        :key="item.name"
      >
        <div class="d-flex justify-content-between">
          <p>
            บัญชีฝาก
            {{
              item.bankcode == "004"
                ? "KBANK"
                : item.bankcode == "903"
                ? "HANA"
                : item.bankcode == "025"
                ? "KRUNGSRI"
                : "-"
            }}
          </p>
          <p>
            {{ item.balance ? Commas(item.balance) : 0 }}
            {{ item.bankcode == "903" ? "วอน" : "บาท" }}
          </p>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <p>บัญชีถอน KBANK</p>
        <p>
          {{ withdraw_kbank_balance ? Commas(withdraw_kbank_balance) : 0 }} บาท
        </p>
      </div>
      <div class="d-flex justify-content-between">
        <p>บัญชีถอน SCB</p>
        <p>
          {{
            withdraw_scb_balance
              ? parseFloat(withdraw_scb_balance).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0
          }}
          บาท
        </p>
      </div>
      <div class="d-flex justify-content-between">
        <p>บัญชีถอน Krungsri</p>
        <p>
          {{
            withdraw_krungsri_balance
              ? parseFloat(withdraw_krungsri_balance).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0
          }}
          บาท
        </p>
      </div>
    </div>
    <b-toast id="example-toast" no-auto-hide header-class="warning">
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-center mr-1">
          <strong class="mr-auto"
            ><i class="fas fa-envelope" /> แจ้งฝากเงิน</strong
          >
          <small class="text-muted">11 mins ago</small>
        </div>
      </template>
      <span
        >Hello, world! This is a toast message. Hope you're doing well..
        :)</span
      >
    </b-toast>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import { BBadge, BToast } from "bootstrap-vue";
// eslint-disable-next-line import/no-unresolved
import TimeAgo from "javascript-time-ago";
// eslint-disable-next-line import/extensions
import en from "javascript-time-ago/locale/en";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";

const data = { soundurl: "/alarm/siren-alert.mp3" };

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    BBadge,
    BToast,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide("openGroups", ref([]));

    return {
      resolveNavItemComponent,
    };
  },
  data() {
    return {
      UserData: JSON.parse(localStorage.getItem("userData")),
      alertwit: null,
      alertwit_kr: null,
      alertwit_check: 0,
      alertwit_check_kr: 0,
      withdraw_balance: 0,
      deposit_balance: 0,
      deposit2_balance: 0,
      deposit3_balance: 0,
      withdraw_kbank_balance: 0,
      withdraw_scb_balance: 0,
      withdraw_krungsri_balance: 0,
      truemoney: 0,
      deposit_kbank_balance: 0,
      crypto_address: "",
      crypto_usdt: 0,
      crypto_bnb: 0,
      agent: null,
      BankData: [],
    };
  },
  destroyed() {
    clearInterval(this.interval);
  },
  async created() {
    this.interval = setInterval(() => {
      if (this.UserData.role !== "zean") {
        // console.log('getWithdraw')
        this.getWithdraw();
        this.getWithdraw_kr();
        if (localStorage.getItem("alertwit")) {
          if (
            JSON.parse(localStorage.getItem("alertwit")).length > 0 &&
            this.agent.alarm === 1
          ) {
            if (
              this.alertwit.length ===
              JSON.parse(localStorage.getItem("alertwit")).length
            ) {
              this.alertwit = JSON.parse(localStorage.getItem("alertwit"));
            } else {
              this.$bvToast.hide("aletrwit");
              this.alertwit = JSON.parse(localStorage.getItem("alertwit"));
            }
            this.alertwit_check = 1;
            const audio = new Audio(data.soundurl);
            audio.play();
          } else if (JSON.parse(localStorage.getItem("alertwit")).length > 0) {
            this.alertwit = JSON.parse(localStorage.getItem("alertwit"));
            this.alertwit_check = 1;
          } else {
            this.alertwit = [];
            this.alertwit_check = null;
          }
        } else {
          this.alertwit = [];
          this.alertwit_check = null;
        }

        if (localStorage.getItem("alertwit_kr")) {
          if (
            JSON.parse(localStorage.getItem("alertwit_kr")).length > 0 &&
            this.agent.alarm === 1
          ) {
            if (
              this.alertwit_kr.length ===
              JSON.parse(localStorage.getItem("alertwit_kr")).length
            ) {
              this.alertwit_kr = JSON.parse(
                localStorage.getItem("alertwit_kr")
              );
            } else {
              this.$bvToast.hide("alertwit_kr");
              this.alertwit_kr = JSON.parse(
                localStorage.getItem("alertwit_kr")
              );
            }
            this.alertwit_check_kr = 1;
            const audio = new Audio(data.soundurl);
            audio.play();
          } else if (
            JSON.parse(localStorage.getItem("alertwit_kr")).length > 0
          ) {
            this.alertwit_kr = JSON.parse(localStorage.getItem("alertwit_kr"));
            this.alertwit_check_kr = 1;
          } else {
            this.alertwit_kr = [];
            this.alertwit_check_kr = null;
          }
        } else {
          this.alertwit_kr = [];
          this.alertwit_check_kr = null;
        }
        this.GetData();
      }
      // this.AlertWit()
    }, 60000);
  },
  async mounted() {
    if (this.UserData.role !== "zean") {
      await this.getWithdraw();
      await this.getWithdraw_kr();
      if (localStorage.getItem("alertwit")) {
        if (JSON.parse(localStorage.getItem("alertwit")).length > 0) {
          this.alertwit = JSON.parse(localStorage.getItem("alertwit"));
          this.alertwit_check = 1;
        } else {
          this.alertwit = [];
          this.alertwit_check = null;
        }
        // this.AlertWit()
      } else {
        this.alertwit = [];
        this.alertwit_check = null;
      }
      if (localStorage.getItem("alertwit_kr")) {
        if (JSON.parse(localStorage.getItem("alertwit_kr")).length > 0) {
          this.alertwit_kr = JSON.parse(localStorage.getItem("alertwit_kr"));
          this.alertwit_check_kr = 1;
        } else {
          this.alertwit_kr = [];
          this.alertwit_check_kr = null;
        }
        // this.AlertWit()
      } else {
        this.alertwit_kr = [];
        this.alertwit_check_kr = null;
      }
      this.GetData();
      this.getAgent();
    }
  },
  methods: {
    getAgent() {
      this.$http
        .get("/agent/show")
        .then((response) => {
          this.agent = response.data;
          this.BankData = this.agent.BankData;
          this.BankData.sort((a, b) => a.no - b.no);
          // console.log(response.data)
        })
        .catch((error) => console.log(error));
    },
    GetData() {
      this.$http
        .get("/agent/GetMenuBalance", {
          params: { CheckTokenID: this.UserData.CheckTokenID },
        })
        .then((response) => {
          this.withdraw_balance = response.data.withdraw_balance;
          this.deposit_balance = response.data.deposit_balance;
          this.deposit2_balance = response.data.deposit2_balance
            ? response.data.deposit2_balance
            : 0;
          this.deposit3_balance = response.data.deposit3_balance
            ? response.data.deposit3_balance
            : 0;
          this.withdraw_kbank_balance = response.data.withdraw_kbank_balance;
          this.withdraw_scb_balance = response.data.withdraw_scb_balance;
          this.withdraw_krungsri_balance =
            response.data.withdraw_krungsri_balance;
          this.truemoney = response.data.truemoney;
          this.deposit_kbank_balance = response.data.deposit_kbank_balance;
          this.crypto_address = response.data.crypto_address;
          if (this.crypto_address) {
            this.getCryptoBalance();
          }
        })
        .catch((error) => console.log(error));
    },
    getCryptoBalance() {
      const params = {
        walletAddress: this.crypto_address,
      };
      this.$http
        .get("/crypto/getbalance", { params })
        .then((response) => {
          this.crypto_usdt = response.data.usdt;
          this.crypto_bnb = response.data.bnb;
        })
        .catch((error) => console.log(error));
    },
    getWithdraw() {
      // let index = 0
      const arry = [];
      this.$http
        .get("/alertwit_count")
        .then((response) => {
          this.show = false;
          this.depositdata = response.data.data;
          // console.log(response.data)
          this.depositdata.forEach((items) => {
            if (items.status === "waiting_approve") {
              arry.push(items);
            }
          });
          localStorage.setItem("alertwit", JSON.stringify(arry));
        })
        .catch((error) => console.log(error));
    },
    getWithdraw_kr() {
      // let index = 0
      const arry = [];
      this.$http
        .get("/alertwit_count_kr")
        .then((response) => {
          this.show = false;
          response.data.data.forEach((items) => {
            if (items.status === "waiting_approve") {
              arry.push(items);
            }
          });
          localStorage.setItem("alertwit_kr", JSON.stringify(arry));
        })
        .catch((error) => console.log(error));
    },
    AlertWit() {
      if (this.alertwit.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.alertwit.length; i++) {
          this.makeToast(this.alertwit[i]);
        }
      }
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x);
        const y = Num.toFixed(2);
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    makeToast(text) {
      // console.log(new Date(text.created_at))
      TimeAgo.addDefaultLocale(en);
      const timeAgo = new TimeAgo("en-US");
      const time = timeAgo.format(new Date(text.created_at));
      // console.log(time)
      this.deposit = true;
      const audio = new Audio(data.soundurl);
      audio.play();

      const h = this.$createElement;
      const vNodesTitle = h(
        "div",
        { class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"] },
        [
          h("strong", { class: "mr-2" }, "💸 แจ้งถอนเงิน"),
          h("small", { class: "ml-auto font-italics" }, time),
        ]
      );

      this.$bvToast.toast(
        `username : ${text.username} ถอนเงิน ${text.amount} บาท`,
        {
          id: "aletrwit",
          title: [vNodesTitle],
          noAutoHide: true,
          variant: "secondary",
          toaster: "b-toaster-bottom-right",
          solid: true,
        }
      );
    },
  },
};
</script>

<style scoped>
.bg-balance {
  /* background-color: #00f8f0; */
  box-shadow: 0 0 0 5px rgba(85, 207, 255, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  color: #7367f0;
}
.transaction-tab {
  flex-wrap: nowrap;
  overflow: hidden;
}
.bg-noti {
  text-align: center;
  background-image: linear-gradient(90deg, #6659de 0%, #8b7cfd 100%);
  padding: 9px 5px !important;
  height: 40px;
}
#flix-tool a {
  color: #eee;
}
.blob {
  height: 20px;
  width: 20px;
  transform: scale(1);
  animation: pulse-black 1s infinite;
}
.blob.red {
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 0.5s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
div p {
  margin: 0;
  font-size: 12px;
}
</style>
